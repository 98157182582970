const navigation = [
	{
		title: 'Farmacia',
		route: 'pharmacy',
		icon: 'FileIcon',
	},
]

const routes = [
	{
		path: '',
		name: '',
		component: () =>
			import(
				/* webpackChunkName: "comain" */
				'@/modules/pharmacy/Main.vue'
			),
		children: [
			{
				path: '/farmacia',
				name: 'pharmacy',
				component: () =>
					import(
						/* webpackChunkName: "pharmacy" */
						'@/modules/pharmacy/Pharmacy.vue'
					),
				meta: {
					pageTitle: 'Farmacia',
					breadcrumb: [
						{
							text: '',
							active: true,
						},
					],
				},
			},
			{
				path: '/patient-medication',
				name: 'patient-medication',
				component: () =>
					import(
						/* webpackChunkName: "patient-medication" */
						'@/modules/pharmacy/PatientMedication.vue'
					),
				meta: {
					pageTitle: 'Farmacia',
					breadcrumb: [
						{
							text: 'Medicamentos paciente',
							active: true,
						},
					],
				},
			},
			{
				path: '/perfil-farmaco',
				name: 'pharmaco-profile',
				component: () =>
					import(
						/* webpackChunkName: "pharmaco-profile" */
						'@/modules/pharmacy/PharmacoProfile.vue'
					),
				meta: {
					pageTitle: 'Farmacia',
					breadcrumb: [
						{
							text: 'Perfil farmaco',
							active: true,
						},
					],
				},
			},
		],
	},
]

export { navigation, routes }
