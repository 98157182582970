<template>
  <h2>Badge</h2>
</template>

<script>
export default {
  name: 'BadgeCustom',
  components: {

  },
  setup() {
    return {}
  },
  data() {
    return {

    }
  },
}
</script>

<style lang="scss">

</style>
