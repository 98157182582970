const RoutesHome = [
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/Home.vue'),
		meta: {
			pageTitle: 'Inicio',
			breadcrumb: [
				{
					text: 'Inicio',
					active: true,
				},
			],
		},
	},
]

export default RoutesHome
