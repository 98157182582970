const navigation = [
	{
		title: 'Gestión de Dietas(Enfermera)',
		children: [
			{
				title: 'Enfermera',
				route: 'nurse',
				icon: 'FileIcon',
			},
		],
	},
]

const routes = [
	{
		path: '',
		name: '',
		component: () =>
			import(
				/* webpackChunkName: "dmkmain" */
				'@/modules/diet_management_nurse/Main.vue'
			),
		children: [
			{
				path: '/',
				redirect: 'gestion-de-dietas/enfermera',
			},
			{
				path: 'gestion-de-dietas/enfermera',
				name: 'nurse',
				component: () =>
					import(
						/* webpackChunkName: "dmknurse" */
						'@/modules/diet_management_nurse/nurse/Nurse'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
						{
							text: 'Enfermera',
							active: true,
						},
					],
				},
			},
		],
	},
]

export { navigation, routes }
