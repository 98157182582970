const navigation = [
	{
		title: 'Gestión de Dietas(Cocina)',
		children: [
			{
				title: 'Cocina',
				route: 'kitchen',
				icon: 'FileIcon',
			},
		],
		// children: [
		// 	{
		// 		title: 'Cocina',
		// 		route: 'kitchen',
		// 		icon: 'FileIcon',
		// 	},
		// 	{
		// 		title: 'Listado de enviados',
		// 		route: 'order-send-list',
		// 		icon: 'FileIcon',
		// 	},
		// 	{
		// 		title: 'Listado de entregados',
		// 		route: 'order-delivered-list',
		// 		icon: 'FileIcon',
		// 	},
		// ],
	},
]

const routes = [
	{
		path: '',
		name: '',
		component: () =>
			import(
				/* webpackChunkName: "dmkmain" */
				'@/modules/diet_management_kitchen/Main.vue'
			),
		children: [
			{
				path: '/',
				redirect: 'gestion-de-dietas/cocina',
			},
			{
				path: 'gestion-de-dietas/cocina',
				name: 'kitchen',
				component: () =>
					import(
						/* webpackChunkName: "dmkdashboard" */
						'@/modules/diet_management_kitchen/kitchen/Kitchen'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
						{
							text: 'Listado de pedidos',
							active: true,
						},
					],
				},
			},
			{
				path: 'gestion-de-dietas/listado-pedidos-enviados',
				name: 'order-send-list',
				component: () =>
					import(
						/* webpackChunkName: "order-send-list" */
						'@/modules/diet_management_kitchen/OrderSendList'
					),
				meta: {
					pageTitle: 'Gestión de dietas ',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
						{
							text: 'Listado de pedidos enviados',
							active: true,
						},
					],
				},
			},
			{
				path: 'gestion-de-dietas/listado-pedidos-enviados',
				name: 'order-delivered-list',
				component: () =>
					import(
						/* webpackChunkName: "order-delivered-list" */
						'@/modules/diet_management_kitchen/OrderDeliveredList'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
						{
							text: 'Listado de pedidos entregados',
							active: true,
						},
					],
				},
			},
		],
	},
]

export { navigation, routes }
