const RoutesLogin = [
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/Login.vue'),
		meta: {
			layout: 'full',
		},
	},
]

export default RoutesLogin
