import DatafieldsRepositoryInterface from '../repositories/interfaces/DatafieldsRepositoryInterface'
import TypeProfileRepositoryInterface from '../repositories/interfaces/TypeProfileRepositoryInterface'
import ListBedRepositoryInterface from '@/repositories/interfaces/ListBedRepositoryInterface'

const bind = (repositoryName, Interface) => ({
	...Object.keys(Interface).reduce((prev, method) => {
		const resolveableMethod = async (...args) => {
			const repository = await import(
				'../repositories/' + repositoryName + '.js'
			)
			return repository.default[method](...args)
		}
		return { ...prev, [method]: resolveableMethod }
	}, {}),
})

export default {
	datafieldsRepository: bind(
		'DatafieldsRepository',
		DatafieldsRepositoryInterface,
	),
	typeProfileRepository: bind(
		'TypeProfileRepository',
		TypeProfileRepositoryInterface,
	),
	listBedRepository: bind(
		'ListBedRepository',
		ListBedRepositoryInterface,
	),
}
