const navigation = [
	{
		title: 'Gestión de Dietas(Nutricionista)',
		children: [
			{
				title: 'Nutricionista',
				route: 'nutritionist',
				icon: 'FileIcon',
			},
			{
				title: 'Listado de platos',
				route: 'list-dishe',
				icon: 'FileIcon',
			},
			{
				title: 'Listado de acompañamientos',
				route: 'list-accompaniment',
				icon: 'FileIcon',
			},
			{
				title: 'Listado de pedidos',
				route: 'order-list',
				icon: 'FileIcon',
			},
			{
				title: 'Horario de pedidos',
				route: 'order-schedule',
				icon: 'FileIcon',
			},
			{
				title: 'Indicadores de gestiòn',
				route: '',
				icon: 'FileIcon',
			},
		],
	},
]

const routes = [
	{
		path: '',
		name: '',
		component: () =>
			import(
				/* webpackChunkName: "dmkmain" */
				'@/modules/diet_management_nutritionist/Main.vue'
			),
		children: [
			{
				path: '/',
				redirect: 'gestion-de-dietas/nutricionista',
			},
			{
				path: '/gestion-de-dietas/nutricionista',
				name: 'nutritionist',
				component: () =>
					import(
						/* webpackChunkName: "dmknutritionist" */
						'@/modules/diet_management_nutritionist/nutritionist/Nutritionist'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
						{
							text: 'Nutricionista',
							active: true,
						},
					],
				},
			},
			{
				path: '/gestion-de-dietas/listado-de-platos/crear-plato',
				name: 'create-dishe',
				component: () =>
					import(
						/* webpackChunkName: "dmkcreatedishe" */
						'@/modules/diet_management_nutritionist/list_of_dishes/CreateDishe'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Listado de platos',
							to: { name: 'list-dishe' },
						},
						{
							text: 'Crear plato',
							active: true,
						},
					],
				},
			},
			{
				path: '/gestion-de-dietas/listado-de-platos/actualizar-plato/:id',
				name: 'update-dishe',
				component: () =>
					import(
						/* webpackChunkName: "dmkupdatedishe" */
						'@/modules/diet_management_nutritionist/list_of_dishes/CreateDishe'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Listado de platos',
							to: { name: 'list-dishe' },
						},
						{
							text: 'Actualizar plato',
							active: true,
						},
					],
				},
			},
			{
				path: '/gestion-de-dietas/listado-de-platos',
				name: 'list-dishe',
				component: () =>
					import(
						/* webpackChunkName: "dmklistdishe" */
						'@/modules/diet_management_nutritionist/list_of_dishes/ListDishe'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Nutricionista',
							to: { name: 'nutritionist' },
						},
						{
							text: 'Listado de platos',
							active: true,
						},
					],
				},
			},
			{
				path: '/gestion-de-dietas/listado-de-acompanamientos',
				name: 'list-accompaniment',
				component: () =>
					import(
						/* webpackChunkName: "dmklistdishe" */
						'@/modules/diet_management_nutritionist/list_of_accompaniments/ListAccompaniment'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Nutricionista',
							to: { name: 'nutritionist' },
						},
						{
							text: 'Listado de acompañamientos',
							active: true,
						},
					],
				},
			},
			{
				path: '/gestion-de-dietas/listado-de-acompanamientos/crear-acompanamiento',
				name: 'create-accompaniment',
				component: () =>
					import(
						/* webpackChunkName: "dmkcreatedishe" */
						'@/modules/diet_management_nutritionist/list_of_accompaniments/CreateAccompaniment'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Listado de acompañamientos',
							to: { name: 'list-accompaniment' },
						},
						{
							text: 'Crear acompañamiento',
							active: true,
						},
					],
				},
			},
			{
				path: '/gestion-de-dietas/listado-de-acompanamientos/actualizar-acompanamiento/:id',
				name: 'update-accompaniment',
				component: () =>
					import(
						/* webpackChunkName: "dmkupdatedishe" */
						'@/modules/diet_management_nutritionist/list_of_accompaniments/CreateAccompaniment'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Listado de acompañamientos',
							to: { name: 'list-accompaniment' },
						},
						{
							text: 'Actualizar acompañamiento',
							active: true,
						},
					],
				},
			},
			{
				path: '/gestion-de-dietas/crear-menu',
				name: 'create-menu',
				component: () =>
					import(
						/* webpackChunkName: "dmkcreatemenu" */
						'@/modules/diet_management_nutritionist/create_menus/CreateMenu'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Crear Menù',
							active: true,
						},
					],
				},
			},
			{
				path: '/gestion-de-dietas/listado-de-pedidos',
				name: 'order-list',
				component: () =>
					import(
						/* webpackChunkName: "dmkorderlist" */
						'@/modules/diet_management_nutritionist/order_list/OrderList'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Nutricionista',
							to: { name: 'nutritionist' },
						},
						{
							text: 'Listado de pedidos',
							active: true,
						},
					],
				},
			},
			{
				path: '/gestion-de-dietas/programar-menu',
				name: 'program-menu',
				component: () =>
					import(
						/* webpackChunkName: "dmkprogrammenu" */
						'@/modules/diet_management_nutritionist/program_menu/ProgramMenu'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Nutricionista',
							to: { name: 'nutritionist' },
						},
						{
							text: 'Programar Menù',
							active: true,
						},
					],
				},
			},
			{
				path: '/gestion-de-dietas/horario-de-pedidos',
				name: 'order-schedule',
				component: () =>
					import(
						/* webpackChunkName: "dmklistdishe" */
						'@/modules/diet_management_nutritionist/order_schedule/OrderSchedule'
					),
				meta: {
					pageTitle: 'Gestión de dietas',
					breadcrumb: [
						{
							text: 'Nutricionista',
							to: { name: 'nutritionist' },
						},
						{
							text: 'Horario de pedidos',
							active: true,
						},
					],
				},
			},
		],
	},
]

export { navigation, routes }
