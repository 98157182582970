const navigation = [
	{
		title: 'Gestión de Recursos Humanos',
		children: [
			{
				title: 'Asistencia',
				route: 'assistance',
				icon: 'FileIcon',
			},
			{
				title: 'Empleados',
				route: 'employee',
				icon: 'FileIcon',
			},
			{
				title: 'Solicitudes pendientes',
				route: 'requests',
				icon: 'FileIcon',
			},
			{
				title: 'Horario',
				route: 'workshift',
				icon: 'FileIcon',
			},
		],
	},
]

const routes = [
	{
		path: '',
		name: '',
		component: () =>
			import(
				/* webpackChunkName: "hrmain" */
				'@/modules/human_resources/Main.vue'
			),
		children: [
			{
				path: '/',
				redirect: '/assistance',
			},
			{
				path: '/asistencia',
				name: 'assistance',
				component: () =>
					import(
						/* webpackChunkName: "hrassistance" */
						'@/modules/human_resources/Dashboard.vue'
					),
				meta: {
					pageTitle: 'Indicadores de Gestión de Asistencia',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
					],
				},
			},
			{
				path: '/empleados',
				name: 'employee',
				component: () =>
					import(
						/* webpackChunkName: "hremployee" */
						'@/modules/human_resources/Employee/Employee.vue'
					),
				meta: {
					pageTitle: 'Empleados',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
						{
							text: 'Empleados',
							active: true,
						},
					],
				},
			},
			{
				path: '/solicitudes',
				name: 'requests',
				component: () =>
					import(
						/* webpackChunkName: "hrrequest" */
						'@/modules/human_resources/Requests/Requests.vue'
					),
				meta: {
					pageTitle: 'Solicitudes pendientes',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
						{
							text: 'Solicitudes pendientes',
							active: true,
						},
					],
				},
			},
			{
				path: '/horario',
				name: 'workshift',
				component: () =>
					import(
						/* webpackChunkName: "hrworkshift" */
						'@/modules/human_resources/Workshifts/Workshift.vue'
					),
				meta: {
					pageTitle: 'Configuración de horarios',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
						{
							text: 'Configuración de horarios',
							active: true,
						},
					],
				},
			},
		],
	},
]

export { navigation, routes }
