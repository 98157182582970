const RoutesError404 = [
	{
		path: '/error-404',
		name: 'error-404',
		component: () => import('@/views/error/Error404.vue'),
		meta: {
			layout: 'full',
		},
	},
	{
		path: '*',
		redirect: 'error-404',
	},
]

export default RoutesError404
