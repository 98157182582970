const navigation = [
	{
		title: 'Clientes',
		route: 'client',
		icon: 'FileIcon',
	},
]

const routes = [
	{
		path: '',
		name: '',
		component: () =>
			import(
				/* webpackChunkName: "comain" */
				'@/modules/user/Main.vue'
			),
		children: [
			{
				path: '/clientes',
				name: 'client',
				component: () =>
					import(
						/* webpackChunkName: "client" */
						'@/modules/user/Listclient.vue'
					),
				meta: {
					pageTitle: 'Clientes',
					breadcrumb: [
						{
							text: 'Listado de Clientes',
							active: true,
						},
					],
				},
			},
			{
				path: '/clientes/usuarios/RolUsuario',
				name: 'Rolusuario',
				component: () =>
					import(
						/* webpackChunkName: "newbrand" */
						'@/modules/user/usuarios/Rolusuario.vue'
					),
				meta: {
					pageTitle: 'Rolusuario',
					breadcrumb: [
						{
							text: 'Registro de Usuarios',
							active: true,
						},
					],
				},
			},
			{
				path: '/clientes/usuarios/Configuracion-Usuario',
				name: 'Configuracion-Usuario',
				component: () =>
					import(
						/* webpackChunkName: "newbrand" */
						'@/modules/user/usuarios/Configuracionusuario.vue'
					),
				meta: {
					pageTitle: 'Usuario',
					breadcrumb: [
						{
							text: 'Configuracion del Usuario',
							active: true,
						},
					],
				},
			},
		],
	},
]

export { navigation, routes }
