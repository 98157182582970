const navigation = [
	{
		title: 'Clientes',
		route: 'client',
		icon: 'FileIcon',
	},
]

const routes = [
	{
		path: '',
		name: '',
		component: () =>
			import(
				/* webpackChunkName: "comain" */
				'@/modules/client/Main.vue'
			),
		children: [
			{
				path: '/clientes',
				name: 'client',
				component: () =>
					import(
						/* webpackChunkName: "client" */
						'@/modules/client/Client.vue'
					),
				meta: {
					pageTitle: 'Clientes',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
						{
							text: 'Clientes',
							active: true,
						},
					],
				},
			},
			{
				path: '/crear-cliente',
				name: 'client-create',
				component: () =>
					import(
						/* webpackChunkName: "client-create" */
						'@/modules/client/FormWizardClient'
					),
				meta: {
					pageTitle: 'Crear cliente',
					breadcrumb: [
						{
							text: 'Clientes',
							to: { name: 'client' },
						},
						{
							text: 'Crear cliente',
							active: true,
						},
					],
				},
			},
			{
				path: '/actualizar-cliente/:uuid',
				name: 'client-update',
				component: () =>
					import(
						/* webpackChunkName: "client-update" */
						'@/modules/client/FormWizardClient'
					),
				meta: {
					pageTitle: 'Actualizar cliente',
					breadcrumb: [
						{
							text: 'Clientes',
							to: { name: 'client' },
						},
						{
							text: 'Actualizar cliente',
							active: true,
						},
					],
				},
			},
			{
				path: '/clientes/usuarios/RolUsuario',
				name: 'Rolusuario',
				component: () =>
					import(
						/* webpackChunkName: "newbrand" */
						'@/modules/client/usuarios/Rolusuario.vue'
					),
				meta: {
					pageTitle: 'Rolusuario',
					breadcrumb: [
						{
							text: 'Registro de Usuarios',
							active: true,
						},
					],
				},
			},
			{
				path: '/clientes/usuarios/Configuracion-Usuario',
				name: 'Configuracion-Usuario',
				component: () =>
					import(
						/* webpackChunkName: "newbrand" */
						'@/modules/client/usuarios/Configuracionusuario.vue'
					),
				meta: {
					pageTitle: 'Usuario',
					breadcrumb: [
						{
							text: 'Configuracion del Usuario',
							active: true,
						},
					],
				},
			},
		],
	},
]

export { navigation, routes }
