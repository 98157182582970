const navigation = [
	{
		title: 'Empresa',
		route: 'company',
		icon: 'FileIcon',
	},
]

const routes = [
	{
		path: '',
		name: '',
		component: () =>
			import(
				/* webpackChunkName: "comain" */
				'@/modules/company/Main.vue'
			),
		children: [
			{
				path: '/empresa',
				name: 'company',
				component: () =>
					import(
						/* webpackChunkName: "company" */
						'@/modules/company/Company.vue'
					),
				meta: {
					pageTitle: 'Empresa',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
					],
				},
			},
			{
				path: '/empresa/editar',
				name: 'companyEdit',
				component: () =>
					import(
						/* webpackChunkName: "companyedit" */
						'@/modules/company/Company.vue'
					),
				meta: {
					pageTitle: 'Empresa',
					breadcrumb: [
						{
							text: 'Actualizar empresa',
							active: true,
						},
					],
				},
			},
			{
				path: '/empresa/sedes/new',
				name: 'newBrand',
				component: () =>
					import(
						/* webpackChunkName: "newbrand" */
						'@/modules/company/Headquarters/New.vue'
					),
				meta: {
					pageTitle: 'Empresa',
					breadcrumb: [
						{
							text: 'Registrar Sede',
							active: true,
						},
					],
				},
			},
			{
				path: '/empresa/sedes/edit',
				name: 'editBrand',
				component: () =>
					import(
						/* webpackChunkName: "editbrand" */
						'@/modules/company/Headquarters/Edit.vue'
					),
				meta: {
					pageTitle: 'Empresa',
					breadcrumb: [
						{
							text: 'Editar Sede',
							active: true,
						},
					],
				},
			},
			{
				path: '/empresa/:customerId/sede/:brandId/service/:serviceId',
				name: 'areas',
				component: () =>
					import(
						/* webpackChunkName: "areas" */
						'@/modules/company/Areas/Area.vue'
					),
				meta: {
					pageTitle: 'Areas',
					breadcrumb: [
						{
							text: 'Empresa',
							to: { name: 'company' },
						},
						{
							text: 'Areas',
							active: true,
						},
					],
				},
			},
		],
	},
]

export { navigation, routes }
