// Aquí se debe guardar la información del usuario logueado
/**
 * FOR TESTING PURPOSE
 */
// const user = {
// 	name: 'Juancho Polo',
// 	email: 'juanchopolo@gmail.com',
// 	permissions: [
// 		{
// 			patients: ['edit', 'delete'],
// 			employee: ['list', 'new', 'edit', 'delete', 'details', 'infoadditional'],
// 			headquarter: ['list', 'new', 'edit', 'delete'],
// 			client: ['list', 'new', 'edit', 'delete'],
// 			patientrisk: ['list', 'new', 'edit', 'delete'],
// 			patientspecialties: ['list', 'new', 'edit', 'delete'],
// 			patiententity: ['list', 'new', 'edit', 'delete'],
// 			patientmedicines: ['list', 'new', 'edit', 'delete'],
// 			patientexams: ['list', 'new', 'edit', 'delete'],
// 			workshift: ['list', 'new', 'edit', 'delete'],
// 			dishe: ['list', 'new', 'edit', 'delete', 'details'],
// 			accompaniment: ['list', 'new', 'edit', 'delete', 'details'],
// 			kitchenorder: ['sendOrder', 'details', 'print'],
// 			ordersent: ['details', 'print'],
// 			orderdelivered: ['details', 'print'],
// 			ordernutritionist: ['details'],
// 			areadevice: ['list', 'new', 'edit', 'delete'],
// 			deviceBrand: ['list', 'new', 'edit', 'delete', 'details'],
// 			generalService: ['list', 'edit', 'details'],
// 			patientExam: ['list', 'new', 'edit', 'delete'],
// 		},
// 	],
// }

// localStorage.setItem('user', JSON.stringify(user))

export default {
	namespaced: true,
	state: {
		loggedUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
	},
	getters: {
		getLoggedUser(state) {
			return state.loggedUser
		},
	},
	mutations: {
		setUser(state, user) {
			state.loggedUser = user
		},
	},
	actions: {
		loadUser({ commit }, payload) {
			const user = {
				config: payload.config,
				customer_id: payload.customer_id,
				email: payload.email,
				name: payload.name,
				profile_id: payload.profile_id,
				profile_name: payload.profile_name ? payload.profile_name : null,
				permissions: [
					{
						patients: ['edit', 'delete'],
						employee: ['list', 'new', 'edit', 'delete', 'details', 'infoadditional'],
						headquarter: ['list', 'new', 'edit', 'delete'],
						client: ['list', 'new', 'edit', 'delete'],
						patientrisk: ['list', 'new', 'edit', 'delete'],
						patientspecialties: ['list', 'new', 'edit', 'delete'],
						patiententity: ['list', 'new', 'edit', 'delete'],
						patientmedicines: ['list', 'new', 'edit', 'delete'],
						patientexams: ['list', 'new', 'edit', 'delete'],
						workshift: ['list', 'new', 'edit', 'delete'],
						dishe: ['list', 'new', 'edit', 'delete', 'details'],
						accompaniment: ['list', 'new', 'edit', 'delete', 'details'],
						kitchenorder: ['sendOrder', 'details', 'print'],
						ordersent: ['details', 'print'],
						orderdelivered: ['details', 'print'],
						ordernutritionist: ['details'],
						areadevice: ['list', 'new', 'edit', 'delete'],
						deviceBrand: ['list', 'new', 'edit', 'delete', 'details'],
						generalService: ['list', 'edit', 'details'],
						patientExam: ['list', 'new', 'edit', 'delete'],
						orderSchedule: ['list', 'new', 'edit', 'delete'],
					},
				],
			}

			localStorage.setItem('user', JSON.stringify(user))
			commit('setUser', user)
		},
	},
}
