const navigation = [
	{
		title: 'Servicios Generales',
		children: [
			{
				title: 'Solicitudes',
				route: 'serviceMaintenance',
				icon: 'FileIcon',
			},
			{
				title: 'Listado de camas',
				route: 'listBed',
				icon: 'FileIcon',
			},
		],
	},
]

const routes = [
	{
		path: '',
		name: '',
		component: () =>
			import(
				/* webpackChunkName: "comain" */
				'@/modules/general_service/Main.vue'
			),
		children: [
			{
				path: '/servicios-generales',
				name: 'serviceMaintenance',
				component: () =>
					import(
						/* webpackChunkName: "serviceMaintenance" */
						'@/modules/general_service/ServiceMaintenance'
					),
				meta: {
					pageTitle: 'Servicios Generales',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
						{
							text: 'Solicitudes',
							active: true,
						},
					],
				},
			},
			{
				path: '/listado-de-camas',
				name: 'listBed',
				component: () =>
					import(
						/* webpackChunkName: "listBed" */
						'@/modules/general_service/ListBedService'
					),
				meta: {
					pageTitle: 'Servicios generales',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
						{
							text: 'Listado de camas',
							active: true,
						},
					],
				},
			},
			{
				path: '/historial-de-camas',
				name: 'historyBed',
				component: () =>
					import(
						/* webpackChunkName: "historyBed" */
						'@/modules/general_service/HistoryBedBlocking'
					),
				meta: {
					pageTitle: 'Servicios generales',
					breadcrumb: [
						{
							text: 'Inicio',
							to: { name: 'home' },
						},
						{
							text: 'Listado de camas',
							to: { name: 'listBed' },
						},
						{
							text: 'Historial de camas',
							active: true,
						},
					],
				},
			},
		],
	},
]

export { navigation, routes }
