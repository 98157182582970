import Vue from 'vue'
import VueRouter from 'vue-router'

import jwtDefaultConfig from '@core/auth/jwt/jwtDefaultConfig'

// Routes
import RoutesHome from './home/home'
import RoutesLogin from './auth/login'
import RoutesError404 from './error-404/error-404'

const modules = process.env.VUE_APP_MODULES.split(',')
const modulesRoutes = []

modules.forEach(module => {
	const { routes } = require('@/modules/' + module + '/config/navigation.js')
	modulesRoutes.push(...routes)
})

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [...RoutesHome, ...modulesRoutes, ...RoutesLogin, ...RoutesError404],
})

router.beforeEach((to, from, next) => {
	if (
		to.name !== 'login' &&
		!localStorage.getItem(jwtDefaultConfig.storageTokenKeyName)
	) {
		next({ name: 'login' })
	} else {
		next()
	}
})

export default router
